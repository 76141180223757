import React from "react";
import Sidebar from "./Sidebar/Sidebar";
import { useTranslation } from "react-i18next";
import Logo from "../assets/Images/Logo.svg";
import Folder from "../assets/Icons/Folder.svg";

const Send = () => {
  const { t } = useTranslation();
  return (
    <div className="flex">
      <div>
        <Sidebar />
      </div>
      <div>
        <div>
          <div className="2xl:w-[71rem] xl:w-[71rem] lg:w-[71rem] md:w-auto sm:w-auto max-sm:w-auto 2xl:bg-[#ffffff08] xl:bg-[#ffffff08] lg:bg-[#ffffff08] md:bg-black sm:bg-black max-sm:bg-black backdrop-blur-[1rem] border-2 border-[#3e3241] h-[6rem] rounded-b-[2rem] 2xl:border-[#3e3241] xl:border-[#3e3241] lg:border-[#3e3241] md:border-black sm:border-black max-sm:border-black">
            <div className="md:flex sm:flex max-sm:flex md:justify-around sm:justify-between max-sm:justify-between 2xl:w-[71rem] xl:w-[71rem] lg:w-[71rem] md:w-auto sm:w-auto max-sm:w-auto pt-2">
              <img
                src={Logo}
                alt="Logo"
                className="pl-2 2xl:hidden xl:hidden lg:hidden md:visible sm:visible max-sm:visible"
              />

              <div className="bg-[#ffffff0f] backdrop-blur-[1rem] 2xl:w-[18.854rem] xl:w-[18.854rem] lg:w-[18.854rem] md:w-[18.54rem] sm:w-[14.625rem] max-sm:w-[14.625rem] 2xl:h-[4.125rem] xl:h-[4.125rem] lg:h-[4.125rem] md:h-[4.125rem] sm:h-[2.938rem] max-sm:h-[2.938rem] rounded-[2.75rem] text-[#ceccce] flex flex-row justify-between p-[1%_3%] pt-3 2xl:pl-6 xl:pl-6 lg:pl-6 md:pl-4 sm:pl-4 max-sm:pl-4 2xl:pt-2 xl:pt-2 lg:pt-2 md:pt-3 sm:pt-3 max-sm:pt-3">
                <select className="block w-full px-4 py-2 pr-8 leading-tight bg-transparent backdrop:blur-[1rem] border-none rounded-md appearance-none focus:outline-none focus:border-blue-500">
                  <option
                    value="option1"
                    className="bg-transparent w-[18.54rem] h-[4.125rem]"
                  >
                    <div className="bg-[#ffffff0f] h-[4rem]">
                      {t("ethereum")}
                    </div>
                  </option>
                  <option
                    value="option2"
                    className="bg-transparent w-[18.54rem] h-[4.125rem]"
                  >
                    {t("bit_coin")}
                  </option>
                </select>
              </div>

              <div className="bg-[#ffffff0f] backdrop-blur-[1rem] w-[18.854rem] h-[4.125rem] rounded-[2.75rem] text-white flex flex-col pt-3 pl-8 2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden">
                <div className="text-[0.75rem] text-[#a7a6ac]">
                  {t("key_base_wallet")}
                </div>
                <div className="text-[0.875rem] flex">
                  {t("wallet_adress")}
                  <img src={Folder} alt="Folder" className="pl-2" />
                </div>
              </div>
              <div className="bg-[#ffffff0f] backdrop-blur-[1rem] w-[18.854rem] h-[4.125rem] rounded-[2.75rem] text-white flex flex-col pt-3 pl-8 2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden">
                <div className="text-[0.75rem] text-[#a7a6ac]">
                  {t("smart_wallet")}
                </div>
                <div className="text-[0.875rem] flex">
                  {t("wallet_adress")}
                  <img src={Folder} alt="Folder" className="pl-2" />
                </div>
              </div>
              <div className="text-[#746e79] font-bold pt-6 pr-8  cursor-pointer hover:text-white 2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden">
                {t("logout")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Send;
