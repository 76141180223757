import React from "react";
import { useTranslation } from "react-i18next";
import menu from "../assets/Icons/menu.svg";
import refresh from "../assets/Icons/refresh.svg";
import Chainsvg from "../assets/Icons/Chainsvg.svg";
import Folder from "../assets/Icons/Folder.svg";

const Deposit = () => {
  const { t } = useTranslation();

  return (
    <div className=" backdrop-blur-[4px]">
      <div className="flex justify-center pt-28 font-inter fixed inset-0">
        <div className="bg-[#0c0c0c66] w-[35rem] h-[33.188rem] rounded-[2.25rem] border-2 border-[#252126] backdrop-blur-[1rem]">
          <div className="flex justify-between p-[4%_7%]">
            <div className="text-white font-bold text-[1.5rem]">
              {t("deposit")}
            </div>
            <div className="flex">
              <img src={menu} alt="menu" />
              <img src={refresh} alt="refresh" className="pl-2" />
            </div>
          </div>
          <div className="flex justify-between p-[0%_7%]">
            <div className="text-[0.875rem] text-[#7f7d80] ">{t("from")}</div>
            <div className="text-[1rem] text-[#7f7d80] font-bold">
              {t("keybasewallet")}
            </div>
          </div>
          <div className="flex justify-center pt-4">
            <div className="bg-[#000000] rounded-3xl w-[31rem] h-[9.688rem]">
              <div className="grid grid-cols-2 p-[4%_5%] cursor-pointer">
                <div className="bg-[#191919] w-[13.5rem] h-[4.5rem] rounded-2xl">
                  <div className="flex p-[7%_6%]">
                    <div>
                      <img src={Chainsvg} alt="Chainsvg" />
                    </div>
                    <div className="text-[white] text-[0.625rem] pl-2 pt-3">
                      {t("select_token")}
                    </div>
                  </div>
                </div>
                <div className=" border-b-2 border-[#333333]">
                  <input
                    type="text"
                    placeholder="0"
                    className="text-[2rem] text-[#7f7d80] outline-none bg-[black] text-right w-[15rem] rounded-2xl pt-2 pr-4"
                  />
                </div>
              </div>
              <div className="flex justify-between p-[0%_5%]">
                <div className="text-[0.875rem] text-[#7f7d80]">
                  {t("balance_2")}
                </div>
                <div className="flex">
                  <div className="bg-[#191919] w-[3.125rem] h-[1.938rem] rounded-xl text-[#d1d1d1] text-[0.75rem] pt-1.5 pl-3 cursor-pointer">
                    25%
                  </div>
                  <div className="bg-[#191919] w-[3.125rem] h-[1.938rem] rounded-xl text-[#d1d1d1] text-[0.75rem] pt-1.5 pl-3 ml-2 cursor-pointer">
                    30%
                  </div>
                  <div className="bg-[#191919] w-[3.125rem] h-[1.938rem] rounded-xl text-[#d1d1d1] text-[0.75rem] pt-1.5 pl-3 ml-2 cursor-pointer">
                    {t("max")}
                  </div>
                  <div className="bg-[#330e10] w-[3.5rem] h-[1.938rem] rounded-xl text-[#d1d1d1] text-[0.75rem] pt-1.5 pl-3 ml-2 cursor-pointer">
                    {t("clear")}
                  </div>
                </div>
              </div>
              <div className="text-[0.875rem] text-[#7f7d80] pt-14">
                {t("to")}
              </div>
              <div className="pt-3">
                <div className="w-[31rem] h-[4.5rem] bg-[black] rounded-3xl pt-3 pl-6 ">
                  <div className="text-[0.75rem] text-[#7f7d80]">
                    {t("smart_wallet")}
                  </div>
                  <div className="text-[0.875rem] text-[#cccccc] font-bold flex">
                    {t("wallet_adress")}
                    <img src={Folder} alt="Folder" className="pl-2" />
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <div className="bg-[#ffffff1a] w-[31rem] h-[3.5rem] rounded-[2.5rem] backdrop-blur-md text-center pt-3.5 cursor-pointer text-[1rem] text-[#ffffff66]">
                  {t("select_token")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
