import kanalabslogo from "../../assets/Images/kanalabslogo.svg";
import React, { useState } from "react";
import Logo from "../../assets/Images/Logo.svg";
import LoginUser from "../../assets/Icons/LoginUser.svg";
import Folder from "../../assets/Icons/Folder.svg";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation();
  const [close, setClose] = useState(true);
  const toggleClose = () => {
    setClose(!close);
  };

  return (
    <div className="font-inter">
      {close && (
        <aside className="">
          <div className="2xl:w-[16rem] xl:w-[16rem] lg:w-[16rem] md:w-full sm:w-full max-sm:w-full 2xl:bg-transparent xl:bg-transparent lg:bg-transparent md:bg-black sm:bg-black max-sm:bg-black">
            <div className="2xl:pl-14 xl:pl-14 lg:pl-14 md:pl-0 sm:pl-0 max-sm:pl-0 2xl:pt-16 xl:pt-16 lg:pt-16 md:pt-0 sm:pt-0 max-sm:pt-0">
              <img
                src={kanalabslogo}
                alt="Kanalogo"
                className="2xl:visible xl:visible lg:visible md:invisible sm:invisible max-sm:invisible"
              />
              <div className="flex justify-between 2xl:hidden xl:hidden lg:hidden md:visible sm:visible max-sm:visible">
                <img src={Logo} alt="Logo" className="pl-6" />
                <img src={LoginUser} alt="LoginUser" className="pr-4" />
              </div>
              <div className="flex justify-center  2xl:hidden xl:hidden lg:hidden md:visible sm:visible max-sm:visible pt-6">
                <div className="w-[24.5rem] h-[4rem] bg-[#ffffff0f] rounded-3xl pt-2 ">
                  <span className="text-[0.75rem] text-[gray] pl-8">
                    {t("key_base_wallet")}
                  </span>
                  <span className="text-[0.875rem] flex text-white font-bold pl-8">
                    {t("wallet_adress")}
                    <img src={Folder} alt="copy" className="pl-2" />
                  </span>
                </div>
              </div>
              <div className="flex justify-center  2xl:hidden xl:hidden lg:hidden md:visible sm:visible max-sm:visible pt-3">
                <div className="w-[24.5rem] h-[4rem] bg-[#ffffff0f] rounded-3xl pt-2">
                  <span className="text-[0.75rem] text-[gray] pl-8">
                    {t("smart_wallet")}
                  </span>
                  <span className="text-[0.875rem] flex text-white font-bold pl-8">
                    {t("wallet_adress")}
                    <img src={Folder} alt="copy" className="pl-2" />
                  </span>
                </div>
              </div>
            </div>

            <div>
              <ul className="text-[1.125rem] 2xl:pl-14 xl:pl-14 lg:pl-14 md:pl-14 sm:pl-7 max-sm:pl-7 2xl:pt-28 xl:pt-28 lg:pt-28 md:pt-16 sm:pt-4 max-sm:pt-4">
                <li className="text-[#433d46] hover:text-white cursor-pointer">
                  <a href="/">{t("dashboard")}</a>
                </li>
                <li className="pt-8 text-[#433d46] hover:text-white cursor-pointer">
                  <a href="/Swap">{t("swap")}</a>
                </li>
                <li className="pt-8 text-[#433d46] hover:text-white cursor-pointer">
                  <a href="/Send">{t("send")}</a>
                </li>
                <li className="pt-8 text-[#433d46] hover:text-white cursor-pointer">
                  <a href="/Trade">{t("trade")}</a>
                </li>
                <li className="pt-8 text-[#433d46] hover:text-white cursor-pointer">
                  <a href="/Collectibles">{t("collectibles")}</a>
                </li>
                <li className="pt-8 text-[#433d46] hover:text-white cursor-pointer">
                  <a href="/">{t("history")}</a>
                </li>
                <li className="pt-8 text-[#433d46] hover:text-white cursor-pointer">
                  <a href="/Addressbook">{t("adress_book")}</a>
                </li>
              </ul>
              <div className="2xl:w-[8.063rem] xl:w-[8.063rem] lg:w-[8.063rem] md:w-[8.063rem] sm:w-full max-sm:w-full h-[2px] bg-[#130817] 2xl:mt-8 xl:mt-8 lg:mt-8 md:mt-8 sm:mt-8 max-sm:mt-8  2xl:ml-12 xl:ml-12 lg:ml-12 md:ml-12 sm:ml-7 max-sm:ml-7"></div>
              <div className="text-[#433d46] 2xl:pt-8 xl:pt-8 lg:pt-8 md:pt-8 sm:pt-8 max-sm:pt-8 2xl:pl-14 xl:pl-14 lg:pl-14 md:pl-14 sm:pl-7 max-sm:pl-7 hover:text-white cursor-pointer">
                {t("help")}
              </div>
              <div
                className="2xl:hidden xl:hidden lg:hidden md:visible sm:visible max-sm:visible text-center text-[1rem] text-[gray] font-bold pt-4"
                onClick={toggleClose}
              >
                {t("logout")}
              </div>
              <br></br>
              <div></div>
            </div>
          </div>
        </aside>
      )}
    </div>
  );
};

export default Sidebar;
