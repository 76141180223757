import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/LandingPage/Login";
import Deposit from "pages/Deposit";
import Send from "pages/Send";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/" element={<Login />} />
          <Route path="/Deposit" element={<Deposit />} />
          <Route path="/Send" element={<Send />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
