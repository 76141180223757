import React, { useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import Folder from "../assets/Icons/Folder.svg";
import Send from "../assets/Icons/Send.svg";
import Plus from "../assets/Icons/Plus.svg";
import Searchbar from "../assets/Icons/Searchbar.svg";
import aptos from "../assets/Images/aptos.svg";
import ETH from "../assets/Images/ETH.svg";
import USDC from "../assets/Images/USDC.svg";
import USDT from "../assets/Images/USDT.svg";
import Whitecircle from "../assets/Images/Whitecircle.svg";
import down from "../assets/Icons/down.svg";
import frame12 from "../assets/Icons/frame12.svg";
import User from "../assets/Icons/User.svg";
import Logo from "../assets/Images/Logo.svg";
import { useTranslation } from "react-i18next";
import Deposit from "./Deposit";
const Dashboard = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const toggleClose = () => {
    setClose(!close);
  };
  const [deposit, setDeposit] = useState(false);
  const toggleDeposit = () => {
    setDeposit(!deposit);
  };
  return (
    <div>
      <div className="2xl:flex  xl:flex lg:flex">
        <div className="2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden">
          {toggleOpen && <Sidebar />}
        </div>
        {toggleClose && (
          <div className=" font-inter text-white 2xl:bg-transparent xl:bg-transparent lg:bg-transparent md:bg-black sm:bg-black max-sm:bg-black 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-4 sm:pt-4 max-sm:pt-4 2xl:pl-8 xl:pl-0 lg:pl-0 md:pl-0">
            <div className="2xl:w-[71rem] xl:w-[71rem] lg:w-[71rem] md:w-auto sm:w-auto max-sm:w-auto 2xl:bg-[#ffffff08] xl:bg-[#ffffff08] lg:bg-[#ffffff08] md:bg-black sm:bg-black max-sm:bg-black backdrop-blur-[1rem] border-2 border-[#3e3241] h-[6rem] rounded-b-[2rem] 2xl:border-[#3e3241] xl:border-[#3e3241] lg:border-[#3e3241] md:border-black sm:border-black max-sm:border-black">
              <div className="md:flex sm:flex max-sm:flex md:justify-around sm:justify-between max-sm:justify-between 2xl:w-[71rem] xl:w-[71rem] lg:w-[71rem] md:w-auto sm:w-auto max-sm:w-auto pt-2">
                <img
                  src={Logo}
                  alt="Logo"
                  className="pl-2 2xl:hidden xl:hidden lg:hidden md:visible sm:visible max-sm:visible"
                />

                <div className="bg-[#ffffff0f] backdrop-blur-[1rem] 2xl:w-[18.854rem] xl:w-[18.854rem] lg:w-[18.854rem] md:w-[18.54rem] sm:w-[14.625rem] max-sm:w-[14.625rem] 2xl:h-[4.125rem] xl:h-[4.125rem] lg:h-[4.125rem] md:h-[4.125rem] sm:h-[2.938rem] max-sm:h-[2.938rem] rounded-[2.75rem] text-[#ceccce] flex flex-row justify-between p-[1%_3%] pt-3 2xl:pl-6 xl:pl-6 lg:pl-6 md:pl-4 sm:pl-4 max-sm:pl-4 2xl:pt-5 xl:pt-5 lg:pt-5 md:pt-2 sm:pt-2 max-sm:pt-2">
                  <select className="block w-full px-4 py-2 pr-8 leading-tight bg-transparent backdrop:blur-[1rem] border-none rounded-md appearance-none focus:outline-none focus:border-blue-500">
                    <option
                      value="option1"
                      className="bg-transparent w-[18.54rem] h-[4.125rem]"
                    >
                      <div className="bg-[#ffffff0f] h-[4rem]">
                        {t("ethereum")}
                      </div>
                    </option>
                    <option
                      value="option2"
                      className="bg-transparent w-[18.54rem] h-[4.125rem]"
                    >
                      {t("bit_coin")}
                    </option>
                  </select>
                </div>

                <div className="2xl:hidden xl:hidden lg:hidden md:inline sm:inline max-sm:inline">
                  <img src={User} alt="User" className="pt-2" />
                </div>

                <div className="bg-[#ffffff0f] backdrop-blur-[1rem] w-[18.854rem] h-[4.125rem] rounded-[2.75rem] text-white flex flex-col pt-3 pl-8 2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden">
                  <div className="text-[0.75rem] text-[#a7a6ac]">
                    {t("key_base_wallet")}
                  </div>
                  <div className="text-[0.875rem] flex">
                    {t("wallet_adress")}
                    <img src={Folder} alt="Folder" className="pl-2" />
                  </div>
                </div>
                <div className="bg-[#ffffff0f] backdrop-blur-[1rem] w-[18.854rem] h-[4.125rem] rounded-[2.75rem] text-white flex flex-col pt-3 pl-8 2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden">
                  <div className="text-[0.75rem] text-[#a7a6ac]">
                    {t("smart_wallet")}
                  </div>
                  <div className="text-[0.875rem] flex">
                    {t("wallet_adress")}
                    <img src={Folder} alt="Folder" className="pl-2" />
                  </div>
                </div>
                <div className="text-[#746e79] font-bold pt-6 pr-8  cursor-pointer hover:text-white 2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden">
                  {t("logout")}
                </div>
              </div>
            </div>

            <div className="2xl:pt-6 xl:pt-6 lg:pt-6 md:pt-2 sm:pt-0 max-sm:pt-0 2xl:flex 2xl:justify-start xl:flex xl:justify-start lg:flex lg:justify-start md:flex md:justify-start sm:flex sm:justify-center max-sm:flex max-sm:justify-center w-auto">
              <div className="2xl:bg-[#ffffff08] xl:bg-[#ffffff08] lg:bg-[#ffffff08] md:bg-black sm:bg-black max-sm:bg-black backdrop-blur-[1rem] border-2 2xl:border-[#3e3241] xl:border-[#3e3241] lg:border-[#3e3241] md:border-black sm:border-black max-sm:border-black 2xl:w-[71rem] xl:w-[71rem] lg:w-[71rem] md:w-full sm:w-auto max-sm:w-auto 2xl:h-[9rem] xl:h-[9rem] lg:h-[9rem] md:h-[52rem] sm:h-[48.063rem] max-sm:h-[48.063rem] rounded-[2rem] 2xl:grid xl:grid lg:grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-none sm:grid-cols-none pt-1">
                <div>
                  <div className="2xl:bg-[#0b060f] xl:bg-[#0b060f] lg:bg-[#0b060f] md:bg-[#141016] sm:bg-[#141016] max-sm:bg-[#141016] 2xl:w-[32rem] xl:w-[32rem] lg:w-[32rem] md:w-full sm:w-[19.5rem] max-sm:w-[19.5rem] 2xl:h-[6.438rem] xl:h-[6.438rem] lg:h-[6.438rem] md:h-[6.438rem] sm:h-[5.25rem] max-sm:h-[5.25rem] rounded-2xl ml-4 2xl:pl-4 xl:pl-4 lg:pl-4 mt-4 flex flex-col">
                    <div className="text-[#9c9a9f] 2xl:text-[1rem] xl:text-[1rem] lg:text-[1rem] md:text-[1rem] sm:text-[0.75rem] max-sm:text-[0.75rem] 2xl:text-start xl:text-start lg:text-start md:text-center sm:text-center max-sm:text-center 2xl:pt-2 xl:pt-2 lg:pt-2 md:pt-4 sm:pt-4 max-sm:pt-4">
                      {t("your_balance")}
                    </div>
                    <div className="text-white 2xl:text-[2.25rem] xl:text-[2.25rem] lg:text-[2.25rem] md:text-[2.25rem] sm:text-[1.5rem] max-sm:text-[1.5rem] font-bold 2xl:text-start xl:text-start lg:text-start md:text-center sm:text-center max-sm:text-center">
                      {t("balance")}
                    </div>
                    <div className="2xl:hidden xl:hidden lg:hidden md:visible sm:visible max-sm:visible flex justify-around pt-7">
                      <div className="w-[9.25rem] h-[3.188rem] rounded-3xl bg-gradient-to-r from-[#00ffff] to-[#00f9a9]">
                        <div
                          className="text-black font-bold text-[1rem] flex justify-center pt-3"
                          onClick={toggleDeposit}
                        >
                          <img src={Plus} alt="Plus" />
                          <p className="pl-2">{t("deposit")}</p>
                        </div>
                      </div>
                      <div className="w-[9.25rem] h-[3.188rem] rounded-3xl bg-gradient-to-r from-[#00ffff] to-[#00f9a9] ml-2">
                        <div className="text-black font-bold text-[1rem] flex justify-center pt-3">
                          <img src={Send} alt="Send" />
                          <p className="pl-2">{t("send")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between 2xl:hidden xl:hidden lg:hidden md:visible sm:visible max-sm:visible md:pl-10 sm:pl-0 max-sm:pl-0 pt-12">
                      <div className="text-[#ceccce] text-[1rem] pt-2 ">
                        {t("yours_tokens")}
                      </div>
                      <div className="flex md:w-[28.5rem] md:h-[2.75rem] sm:w-[9.5rem] max-sm:h-[2.563rem] sm:h-[2.563rem] max-sm:w-[9.5rem] rounded-[0.5rem] border-2 border-[#3e3241] bg-[#0e0712] text-[0.875rem] outline-none ml-14">
                        <img
                          src={Searchbar}
                          alt="Searchbar"
                          className="w-[1rem] h-[1rem] mt-3 ml-3 "
                        />
                        <input
                          className=" bg-[#0e0712]  border-[255,255,255,0.2] w-[7.2rem] text-sm font-bold  outline-none pl-2"
                          placeholder="Search"
                        />
                      </div>
                      <div></div>
                    </div>
                    <div className="2xl:hidden xl:hidden lg:hidden md:visible sm:visible max-sm:visible pt-16">
                      <table className="w-full">
                        <tbody className="overflow-auto scrollbar-hide">
                          <tr className="border-b border-[#ffffff1a] w-full h-16">
                            <td className="whitespace-nowrap">
                              <img src={aptos} alt="Aptos" />
                            </td>
                            <td className="text-[white] font-bold text-[0.875rem] pl-3">
                              {t("apt")}
                            </td>
                            <td className="text-white font-bold pl-24">
                              {t("chain_balance")}
                            </td>
                            <td className="rounded-full cursor-pointer pl-2">
                              <div className="rounded-full bg-[#081c1c] h-[2.5rem] w-[2.5rem]">
                                <img
                                  src={down}
                                  alt="send"
                                  className="pl-3.5 pt-3"
                                />
                              </div>
                            </td>
                            <td className="rounded-full cursor-pointer pl-2">
                              <div className="rounded-full bg-[#081c1c] h-[2.5rem] w-[2.5rem]">
                                <img
                                  src={frame12}
                                  alt="send"
                                  className="pl-3 pt-3"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-b border-[#ffffff1a] w-full h-16">
                            <td className="whitespace-nowrap">
                              <img
                                src={USDC}
                                alt="Aptos"
                                className="w-[1.5rem] h-[1.5rem]"
                              />
                            </td>
                            <td className="text-[white] font-bold text-[0.875rem] pl-3">
                              {t("usdc")}
                            </td>
                            <td className="text-white font-bold pl-24">
                              {t("chain_balance")}
                            </td>
                            <td className="rounded-full cursor-pointer pl-2">
                              <div className="rounded-full bg-[#081c1c] h-[2.5rem] w-[2.5rem]">
                                <img
                                  src={down}
                                  alt="send"
                                  className="pl-3.5 pt-3"
                                />
                              </div>
                            </td>
                            <td className="rounded-full cursor-pointer pl-2">
                              <div className="rounded-full bg-[#081c1c] h-[2.5rem] w-[2.5rem]">
                                <img
                                  src={frame12}
                                  alt="send"
                                  className="pl-3 pt-3"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-b border-[#ffffff1a] w-full h-16">
                            <td className="whitespace-nowrap">
                              <img
                                src={USDT}
                                alt="Aptos"
                                className="w-[1.5rem] h-[1.5rem]"
                              />
                            </td>
                            <td className="text-[white] font-bold text-[0.875rem] pl-3">
                              {t("usdt")}
                            </td>
                            <td className="text-white font-bold pl-24">
                              {t("chain_balance")}
                            </td>
                            <td className="rounded-full cursor-pointer pl-2">
                              <div className="rounded-full bg-[#081c1c] h-[2.5rem] w-[2.5rem]">
                                <img
                                  src={down}
                                  alt="send"
                                  className="pl-3.5 pt-3"
                                />
                              </div>
                            </td>
                            <td className="rounded-full cursor-pointer pl-2">
                              <div className="rounded-full bg-[#081c1c] h-[2.5rem] w-[2.5rem]">
                                <img
                                  src={frame12}
                                  alt="send"
                                  className="pl-3 pt-3"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-b border-[#ffffff1a] w-full h-16">
                            <td className="whitespace-nowrap">
                              <img
                                src={ETH}
                                alt="Aptos"
                                className="w-[1.5rem] h-[1.5rem]"
                              />
                            </td>
                            <td className="text-[white] font-bold text-[0.875rem] pl-3">
                              {t("eth")}
                            </td>
                            <td className="text-white font-bold pl-24">
                              {t("chain_balance")}
                            </td>
                            <td className="rounded-full cursor-pointer pl-2">
                              <div className="rounded-full bg-[#081c1c] h-[2.5rem] w-[2.5rem]">
                                <img
                                  src={down}
                                  alt="send"
                                  className="pl-3.5 pt-3"
                                />
                              </div>
                            </td>
                            <td className="rounded-full cursor-pointer pl-2">
                              <div className="rounded-full bg-[#081c1c] h-[2.5rem] w-[2.5rem]">
                                <img
                                  src={frame12}
                                  alt="send"
                                  className="pl-3 pt-3"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="2xl:hidden xl:hidden lg:hidden md:visible sm:visible max-sm:visible">
                      <div className="font-bold pt-6 text-[#ceccce] text-[1rem] md:text-center sm:text-start max-sm:text-start">
                        {t("previous_transaction")}
                      </div>
                      <div>
                        <div className="flex md:justify-center pt-8">
                          <div className="rounded-full w-[1.3rem] h-[1.3rem] bg-[white] mt-0.5"></div>
                          <p className="text-[#938c95] text-[0.875rem] pt-1 pl-1">
                            {t("token_name")}
                          </p>
                          <p className="text-[1rem] text-[#802b35] pt-1 pl-40">
                            {t("chain_balance")}
                          </p>
                        </div>
                        <div className="w-[17.5rem] border-b-[#281c2c] border-y-2 flex ml-7 mt-5 opacity-5"></div>
                        <div className="flex md:justify-center pt-5 ">
                          <div className="rounded-full w-[1.3rem] h-[1.3rem] bg-[white] mt-0.5"></div>

                          <p className="text-[#938c95] text-[0.875rem] pt-1 pl-1 ">
                            {t("token_name")}
                          </p>
                          <p className="text-[1rem] text-[#18a66e] pt-1 pl-40">
                            {t("chain_balance")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="2xl:flex 2xl:flex-row xl:flex xl:flex-row lg:flex lg:flex-row pt-10 pl-60 2xl:visible xl:visible lg:visible md:hidden sm:hidden max-sm:hidden">
                  <div className="w-[8.188rem] h-[3.188rem] rounded-3xl bg-gradient-to-r from-[#00ffff] to-[#00f9a9] cursor-pointer">
                    <div className="text-black font-bold text-[1rem] flex justify-center pt-3">
                      <img src={Plus} alt="Plus" />
                      <p className="pl-2"> {t("deposit")}</p>
                    </div>
                  </div>
                  <div className="w-[6.938rem] h-[3.188rem] rounded-3xl bg-gradient-to-r from-[#00ffff] to-[#00f9a9] ml-3 cursor-pointer">
                    <div className="text-black font-bold text-[1rem] flex justify-center pt-3 ">
                      <img src={Send} alt="Send" />
                      <p className="pl-2"> {t("send")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="2xl:contents xl:contents lg:contents md:hidden sm:hidden max-sm:hidden">
              <div className="pt-4 flex">
                <div className="bg-[#ffffff08] backdrop-blur-[1rem] border-2 border-[#3e3241] w-[47rem] h-[36.188rem] rounded-[2rem]">
                  <div className="grid grid-cols-2 p-[4%_4%]">
                    <div className="text-white text-[1.25rem] pt-2 font-bold ">
                      {t("your_tokens")}
                    </div>
                    <div className="flex w-[20.75rem] h-[2.563rem] rounded-[0.5rem] border-2 border-[#3e3241] bg-[#0e0712] text-[0.875rem] outline-none ml-3">
                      <img
                        src={Searchbar}
                        alt="Searchbar"
                        className="w-[1rem] h-[1rem] mt-3 ml-3 "
                      />
                      <input
                        className=" bg-[#0e0712] W-[19rem] border-[255,255,255,0.2] text-sm font-bold  outline-none pl-2"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="w-[43rem] h-[28.125rem] bg-[#0b040d] rounded-[1rem] overflow-auto scrollbar-hide">
                      <table className="min-w-full text-left text-sm font-light">
                        <tbody className="">
                          <tr className="border-b border-[#ffffff1a] w-full h-20">
                            <td className="whitespace-nowrap px-6 py-6 font-medium flex">
                              <img src={aptos} alt="Aptos" />
                              <div className="pl-3">
                                <div className="text-[1.125rem] font-bold">
                                  {t("apt")}
                                </div>
                                <div className="font-normal text-[gray]">
                                  {t("aptos_coins")}
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td className="whitespace-nowrap  py-3 font-bold pl-32 text-[1.25rem]">
                              {t("chain_balance")}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 cursor-pointer">
                              <div className="bg-[#0a1c1d] text-[0.875rem] font-bold w-[5.75rem] h-[2.5rem] rounded-3xl text-[#02c286] flex pl-3">
                                <img
                                  src={down}
                                  alt="buy"
                                  className="w-[0.656rem] h-[0.75rem] mt-3 ml-1"
                                />
                                <p className="pt-2 pl-3 "> {t("send")}</p>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 cursor-pointer">
                              <div className="bg-[#0a1c1d] text-[0.875rem] font-bold w-[5.188rem] h-[2.5rem] rounded-3xl text-[#02c286] flex pl-3">
                                <img
                                  src={frame12}
                                  alt="buy"
                                  className="w-[0.956rem] h-[0.95rem] mt-2.5"
                                />
                                <p className="pt-2 pl-2.5 ">{t("buy")}</p>
                              </div>
                            </td>
                          </tr>
                          <tr className="border-b border-[#ffffff1a] w-full h-20">
                            <td className="whitespace-nowrap px-6 py-6 font-medium flex">
                              <img src={USDC} alt="Aptos" />
                              <div className="pl-3">
                                <div className="text-[1.125rem] font-bold">
                                  {t("usdc")}
                                </div>
                                <div className="font-normal text-[gray]">
                                  {t("usdc_coin_wormhole")}
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td className="whitespace-nowrap  py-3 font-bold pl-32 text-[1.25rem]">
                              {t("chain_balance")}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 cursor-pointer">
                              <div className="bg-[#0a1c1d] text-[0.875rem] font-bold w-[5.75rem] h-[2.5rem] rounded-3xl text-[#02c286] flex pl-3">
                                <img
                                  src={down}
                                  alt="buy"
                                  className="w-[0.656rem] h-[0.75rem] mt-3 ml-1"
                                />
                                <p className="pt-2 pl-3 "> {t("send")}</p>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 cursor-pointer">
                              <div className="bg-[#0a1c1d] text-[0.875rem] font-bold w-[5.188rem] h-[2.5rem] rounded-3xl text-[#02c286] flex pl-3">
                                <img
                                  src={frame12}
                                  alt="buy"
                                  className="w-[0.956rem] h-[0.95rem] mt-2.5"
                                />
                                <p className="pt-2 pl-2.5 ">{t("buy")}</p>
                              </div>
                            </td>
                          </tr>
                          <tr className="border-b border-[#ffffff1a] w-full h-20">
                            <td className="whitespace-nowrap px-6 py-6 font-medium flex">
                              <img src={USDT} alt="Aptos" />
                              <div className="pl-3">
                                <div className="text-[1.125rem] font-bold">
                                  {t("usdt")}
                                </div>
                                <div className="font-normal text-[gray]">
                                  {t("tether_USD_Wormhole")}
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td className="whitespace-nowrap  py-3 font-bold pl-32 text-[1.25rem]">
                              {t("chain_balance")}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 cursor-pointer">
                              <div className="bg-[#0a1c1d] text-[0.875rem] font-bold w-[5.75rem] h-[2.5rem] rounded-3xl text-[#02c286] flex pl-3">
                                <img
                                  src={down}
                                  alt="buy"
                                  className="w-[0.656rem] h-[0.75rem] mt-3 ml-1"
                                />
                                <p className="pt-2 pl-3 "> {t("send")}</p>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 cursor-pointer">
                              <div className="bg-[#0a1c1d] text-[0.875rem] font-bold w-[5.188rem] h-[2.5rem] rounded-3xl text-[#02c286] flex pl-3">
                                <img
                                  src={frame12}
                                  alt="buy"
                                  className="w-[0.956rem] h-[0.95rem] mt-2.5"
                                />
                                <p className="pt-2 pl-2.5 ">{t("buy")}</p>
                              </div>
                            </td>
                          </tr>
                          <tr className="w-full h-20">
                            <td className="whitespace-nowrap px-6 py-6 font-medium flex">
                              <img src={ETH} alt="Aptos" />
                              <div className="pl-3">
                                <div className="text-[1.125rem] font-bold">
                                  {t("eth")}
                                </div>
                                <div className="font-normal text-[gray]">
                                  {t("eth")}
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td className="whitespace-nowrap  py-3 font-bold pl-32 text-[1.25rem]">
                              {t("chain_balance")}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 cursor-pointer">
                              <div className="bg-[#0a1c1d] text-[0.875rem] font-bold w-[5.75rem] h-[2.5rem] rounded-3xl text-[#02c286] flex pl-3">
                                <img
                                  src={down}
                                  alt="buy"
                                  className="w-[0.656rem] h-[0.75rem] mt-3 ml-1"
                                />
                                <p className="pt-2 pl-3 "> {t("send")}</p>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 cursor-pointer">
                              <div className="bg-[#0a1c1d] text-[0.875rem] font-bold w-[5.188rem] h-[2.5rem] rounded-3xl text-[#02c286] flex pl-3">
                                <img
                                  src={frame12}
                                  alt="buy"
                                  className="w-[0.956rem] h-[0.95rem] mt-2.5"
                                />
                                <p className="pt-2 pl-2.5 "> {t("buy")}</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="pl-4">
                  <div className="bg-[#ffffff08] backdrop-blur-[1rem] border-2 border-[#3e3241] h-[36.188rem] w-[23rem] rounded-[2rem]">
                    <div>
                      <div className="text-white font-bold text-[1.25rem] p-[9%_8%]">
                        {t("previous_transaction")}
                      </div>
                      <div className=" overflow-auto scrollbar-hide h-full">
                        <div className="flex pl-7 ">
                          <img src={Whitecircle} alt="Whitecircle" />
                          <p className="text-[#938c95] text-[1rem] pt-2 pl-2">
                            {t("token_name")}
                          </p>
                          <p className="text-[1rem] text-[#802b35] pt-2 pl-28">
                            {t("chain_balance")}
                          </p>
                        </div>
                        <div className="w-[19rem] border-b-[#281c2c] border-y-2 flex ml-7 mt-7 opacity-5"></div>
                        <div className="flex pl-7 pt-8 ">
                          <img src={Whitecircle} alt="Whitecircle" />
                          <p className="text-[#938c95] text-[1rem] pt-2 pl-2">
                            {t("token_name")}
                          </p>
                          <p className="text-[1rem] text-[#18a66e] pt-2 pl-28">
                            {t("chain_balance")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {deposit && <Deposit />}
    </div>
  );
};

export default Dashboard;
