import React from "react";
import Searchbar from "../../assets/Icons/Searchbar.svg";
import Google from "../../assets/Icons/Google.svg";
import Twitter from "../../assets/Icons/Twitter.svg";
import Facebook from "../../assets/Icons/Facebook.svg";
import Email from "../../assets/Icons/Email.svg";
import Group from "../../assets/Icons/Group.svg";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import Metamaskfox from "../../assets/Icons/Metamaskfox.svg";
import Coinbase from "../../assets/Icons/Coinbase.svg";
import Phantom from "../../assets/Icons/Phantom.svg";

const Login = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-[#0f0114] font-inter">
      <img src={Group} alt="Group" className="absolute w-full pt-48" />
      <div className="pt-32 flex justify-center relative">
        <div className="w-[38rem] h-[38.5rem] bg-[#00000033] border-[#352c39] border-2 rounded-[2.25rem] backdrop-blur-lg">
          <div className="flex justify-between p-[4%_5%]">
            <div className="text-[#ffffff] font-bold text-[1.5rem]">
              {t("signin")}
            </div>
          </div>
          <div className="flex justify-center">
            <div className="w-[34rem] h-[3rem] bg-[#08010a] border-2 border-[#39373d] rounded-xl flex p-[2%_3%]">
              <img src={Searchbar} alt="Searchbar" className="" />
              <input
                type="text"
                placeholder="Search"
                className="w-[30rem] bg-[#08010a] outline-none text-[#7e7c80] font-semibold pl-2"
              />
            </div>
          </div>

          <Tabs className="pt-4">
            <TabList className="flex items-center justify-center bg-[#110715] w-[34rem] h-[3.5rem] m-auto rounded-3xl backdrop-blur-sm ">
              <Tab className="social_web_login w-[17rem] h-[3.5rem] text-center pt-3 text-[1.25rem] font-bold text-[#7e7c80] cursor-pointer hover:text-white hover:bg-[#2c2734] rounded-3xl border-none">
                {t("social")}
              </Tab>
              <Tab className="social_web_login w-[17rem] h-[3.5rem] text-center pt-3 text-[1.25rem] font-bold text-[#7e7c80] cursor-pointer hover:text-white hover:bg-[#2c2734] rounded-3xl border-none">
                {t("web3_wallets")}
              </Tab>
            </TabList>
            <TabPanel className=" pt-3 flex justify-center">
              <div className="bg-[#180f1c] w-[34rem] h-[22.5rem] rounded-3xl">
                <Link
                  to="/smart-wallet"
                  className="backdrop-blur-[24px] flex-[0.49] w-[34rem] h-[5.6rem] hover:bg-[#2c2734] rounded-3xl flex flex-row items-center justify-start font-bold text-[1.25rem] pt-2 pl-2 text-white cursor-pointer "
                >
                  <img src={Google} alt="google" className="mx-8" />
                  {t("google")}
                </Link>
                <Link
                  to="/smart-wallet"
                  className="backdrop-blur-[24px] flex-[0.49] w-[34rem] h-[5.6rem] hover:bg-[#2c2734] rounded-3xl flex flex-row items-center justify-start font-bold text-[1.25rem] pt-2 pl-2 text-white cursor-pointer "
                >
                  <img src={Facebook} alt="Apple" className="mx-8  " />
                  {t("facebook")}
                </Link>
                <Link
                  to="/smart-wallet"
                  className="backdrop-blur-[24px] flex-[0.49] w-[34rem] h-[5.6rem] hover:bg-[#2c2734] rounded-3xl flex flex-row items-center justify-start font-bold text-[1.25rem] pt-2 pl-2 text-white cursor-pointer "
                >
                  <img src={Twitter} alt="google" className="mx-8" />
                  {t("twitter")}
                </Link>
                <Link
                  to="/smart-wallet"
                  className="backdrop-blur-[24px] flex-[0.49] w-[34rem] h-[5.6rem] hover:bg-[#2c2734] rounded-3xl flex flex-row items-center justify-start font-bold text-[1.25rem] pt-2 pl-2 text-white cursor-pointer "
                >
                  <img src={Email} alt="Apple" className="mx-8  " />
                  {t("email")}
                </Link>
              </div>
            </TabPanel>
            <TabPanel className="flex justify-center">
              <div className="bg-[#180f1c] w-[34rem] h-[22.5rem] rounded-3xl">
                <Link
                  to="/smart-wallet"
                  className="backdrop-blur-[24px] flex-[0.49] w-[34rem] h-[5.6rem] hover:bg-[#2c2734] rounded-3xl flex flex-row items-center justify-start font-bold text-[1.25rem] pt-2 pl-2 text-white cursor-pointer "
                >
                  <img src={Metamaskfox} alt="Apple" className="mx-8  " />
                  {t("metamask")}
                </Link>{" "}
                <Link
                  to="/smart-wallet"
                  className="backdrop-blur-[24px] flex-[0.49] w-[34rem] h-[5.6rem] hover:bg-[#2c2734] rounded-3xl flex flex-row items-center justify-start font-bold text-[1.25rem] pt-2 pl-2 text-white cursor-pointer "
                >
                  <img src={Coinbase} alt="Apple" className="mx-8  " />
                  {t("coin_base")}
                </Link>{" "}
                <Link
                  to="/smart-wallet"
                  className="backdrop-blur-[24px] flex-[0.49] w-[34rem] h-[5.6rem] hover:bg-[#2c2734] rounded-3xl flex flex-row items-center justify-start font-bold text-[1.25rem] pt-2 pl-2 text-white cursor-pointer "
                >
                  <img src={Phantom} alt="Apple" className="mx-8  " />
                  {t("phantom")}
                </Link>
              </div>
            </TabPanel>
          </Tabs>
          {/* <div className="flex justify-center pt-2">
            <div className="bg-[#180f1c] w-[34rem] h-[22.5rem] rounded-2xl ">
              <div className="w-[34rem] h-[6rem] hover:bg-[#1b1622] rounded-2xl cursor-pointer">
                <div className="flex pt-7 pl-7 ">
                  <img src={Google} alt="Google" className="" />
                  <div className="text-[white] font-bold text-[1.25rem] pt-2 pl-6">
                    {t("google")}
                  </div>
                </div>
              </div>
              <div className="w-[34rem] h-[6rem] hover:bg-[#1b1622] rounded-2xl cursor-pointer">
                <div className="flex pt-7 pl-7 ">
                  <img src={Facebook} alt="Facebook" className="" />
                  <div className="text-[white] font-bold text-[1.25rem] pl-6 pt-2">
                    {t("facebook")}
                  </div>
                </div>
              </div>
              <div className="w-[34rem] h-[6rem] hover:bg-[#1b1622] rounded-2xl cursor-pointer">
                <div className="flex pt-7 pl-7 ">
                  <img src={Twitter} alt="Twitter" className="" />
                  <div className="text-[white] font-bold text-[1.25rem] pl-6 pt-2">
                    {t("twitter")}
                  </div>
                </div>
              </div>
              <div className="w-[34rem] h-[6rem] hover:bg-[#1b1622] rounded-2xl cursor-pointer">
                <div className="flex pt-7 pl-7 ">
                  <img src={Email} alt="Email" className="" />
                  <div className="text-[white] font-bold text-[1.25rem] pl-6 pt-2">
                    {t("email")}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
